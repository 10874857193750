import { Component, OnInit } from '@angular/core'
import { OrderDetailItem, OrdersAPIService } from '@resultsgate-ui/api'
import { ActivatedRoute } from '@angular/router'
import { finalize } from 'rxjs/operators'
import { CacheService, SelectItem } from '@resultsgate-ui/shared'

@Component({
    templateUrl: './order-summary.component.html',
    styles: [],
})
export class OrderSummaryComponent implements OnInit {
    currentOrder: OrderDetailItem
    id: number
    loading = false
    extractionMethodMap: { [key: string]: SelectItem<number> }
    constructor(private route: ActivatedRoute, private api: OrdersAPIService, private cache: CacheService) {
        this.extractionMethodMap = this.cache.extractionMethodMap
    }

    ngOnInit(): void {
        this.id = +this.route.snapshot.paramMap.get('id')
        this.loading = true
        this.api
            .findOrderById({ id: this.id })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe((resp) => {
                this.currentOrder = resp
                this.currentOrder.items.sort((a, b) => {
                    return a.sample.id - b.sample.id || a.analysisId - b.analysisId
                })
            })
    }
}
