import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { OrderDraftDTO, OrderDraftsAPIService, OrderListItem, OrdersAPIService } from '@resultsgate-ui/api'
import { OrderStatusSelectItemsWEmpty, SelectItem, State, Stateful } from '@resultsgate-ui/shared'
import { Observable } from 'rxjs'
import { finalize, map } from 'rxjs/operators'

class ShortDatePipe extends DatePipe {
    public transform(value): any {
        return super.transform(value, 'short')
    }
}

@Component({
    templateUrl: './my-orders.component.html',
    styleUrls: ['./my-orders.component.css'],
})
@Stateful({ name: 'MyOrdersComponent' })
export class MyOrdersComponent implements OnInit {
    loading: boolean
    @State()
    form: UntypedFormGroup
    statuses: SelectItem<string>[]
    @State()
    orderList$: Observable<OrderListItem[]>
    @State()
    drafts$: Observable<OrderDraftDTO>

    constructor(
        private fb: UntypedFormBuilder,
        private api: OrdersAPIService,
        private txService: TranslateService,
        private orderDraftApi: OrderDraftsAPIService
    ) {
        this.statuses = OrderStatusSelectItemsWEmpty.map((si) => ({
            id: si.id,
            label: this.txService.instant(si.label),
        }))
    }

    ngOnInit() {
        // console.log(`on init state ${this.orderList$}`)
        // const dateMinus12Months = new Date()
        // dateMinus12Months.setMonth(dateMinus12Months.getMonth() - 12)
        this.form = this.fb.group({
            from: [null],
            to: [new Date()],
            status: [null],
        })

        this.search()
    }

    search() {
        this.loading = true
        this.drafts$ = this.orderDraftApi
            .getActiveDrafts()
            .pipe(map((drafts) => (drafts.length > 0 ? drafts[0] : null)))
        this.orderList$ = this.api
            .getOrders({
                dateFrom: this.form.value.from && this.form.value.from.toISOString(),
                dateTo: this.form.value.to && this.form.value.to.toISOString(),
                status: this.form.value.status || undefined,
            })
            .pipe(
                finalize(() => {
                    this.loading = false
                })
            )
    }
}
