import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import * as Sentry from '@sentry/angular-ivy'
import { Feedback } from '@sentry/angular-ivy'

import { filter, map, mergeMap, tap } from 'rxjs/operators'
import { navItems } from './menu-items'

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body',
    template: `
        <ng-container *ngIf="initDone" cas>
            <ng-container *ngIf="shell; else noShell">
                <ui-layout [sideBarMenuItems]="menuItems"></ui-layout>
            </ng-container>

            <ng-template #noShell>
                <div class="container">
                    <router-outlet *ngIf="initDone"></router-outlet>
                </div>
            </ng-template>

            <div class="float" *ngIf="!shell" pTooltip="{{ 'feedback.buttonTooltip' | translate }}">
                <button
                    class="p-ripple p-element p-button p-component p-button-outlined m-0"
                    (click)="showSentryFeedback()"
                >
                    <span data-pc-section="icon" class="pi pi-comments p-button-icon p-button-icon-left"></span>
                    <span class="p-button-label feedback-text" aria-hidden="false" data-pc-section="label">{{
                        'feedback.bigButtonPrompt' | translate
                    }}</span>
                </button>
            </div>
        </ng-container>
    `,
})
export class AppComponent implements OnInit {
    menuItems = navItems
    shell = false
    initDone = false

    constructor(private router: Router, private route: ActivatedRoute, private ngxTranslate: TranslateService) {}

    ngOnInit() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap((event) => {
                    window.scrollTo(0, 0)
                }),
                map(() => this.route),
                map((route) => {
                    while (route.firstChild) route = route.firstChild
                    return route
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event) => {
                // console.log(`route change ${this.route.snapshot}`)
                this.shell = !(event.shell === false)
                this.initDone = true
            })
    }

    showSentryFeedback() {
        console.log(`showSentryFeedback ${this.ngxTranslate.currentLang}`)
        const feedback = Sentry.getClient().getIntegration(Feedback)
        const lang = this.ngxTranslate.currentLang

        feedback.options.buttonLabel = lang === 'sk' ? 'SK ' : 'EN'
        //translate all labels
        feedback.options.cancelButtonLabel = this.ngxTranslate.instant('feedback.cancelButtonLabel')
        feedback.options.emailLabel = this.ngxTranslate.instant('feedback.emailLabel')
        feedback.options.emailPlaceholder = this.ngxTranslate.instant('feedback.emailPlaceholder')
        feedback.options.formTitle = this.ngxTranslate.instant('feedback.formTitle')
        feedback.options.nameLabel = this.ngxTranslate.instant('feedback.nameLabel')
        feedback.options.namePlaceholder = this.ngxTranslate.instant('feedback.namePlaceholder')
        feedback.options.messageLabel = this.ngxTranslate.instant('feedback.messageLabel')
        feedback.options.messagePlaceholder = this.ngxTranslate.instant('feedback.messagePlaceholder')
        feedback.options.submitButtonLabel = this.ngxTranslate.instant('feedback.submitButtonLabel')
        feedback.options.successMessageText = this.ngxTranslate.instant('feedback.successMessageText')

        feedback.remove()
        feedback.setupOnce()
        feedback.openDialog()
        // getClient().getIntegration(Feedback).openDialog()
    }
}
