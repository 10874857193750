<h4>{{ 'order.paymentAndDelivery' | translate }}</h4>

<div class="text-left">
    <form novalidate [formGroup]="headerForm" class="form-horizontal">
        <div class="form-group row">
            <label class="col-form-label col-sm-4">{{ 'order.samplingKitRequested' | translate }}</label>
            <div class="col-sm-8 col-xl-6 text-left">
                <div class="row no-gutters">
                    <ng-select
                        formControlName="samplingKitType"
                        title="{{ 'samplingOrder.samplingMethod' | translate }}"
                        [searchable]="false"
                        [clearable]="false"
                        bindValue="id"
                        style="flex: 1"
                        [items]="extractionMethodSelectItems"
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <div *ngIf="headerForm?.controls.samplingKitType.value !== -1" class="form-group row">
            <label class="col-form-label col-sm-4" for="samplingKitCount">{{
                'samplingOrder.numberOfSamples' | translate
            }}</label>
            <div class="col-sm-8">
                <input
                    class="form-control col-sm-5"
                    id="samplingKitCount"
                    type="number"
                    formControlName="samplingKitCount"
                />
                <ui-show-errors controlName="samplingKitCount"></ui-show-errors>
            </div>
        </div>
        <div class="form-group row required">
            <label class="col-form-label col-sm-4">{{ 'order.paymentMethod' | translate }}</label>
            <div class="col-sm-8 col-xl-6">
                <div class="row no-gutters">
                    <ng-select formControlName="paymentMethod" [searchable]="false" [clearable]="false" style="flex: 1">
                        <ng-option value="PAYPAL">PayPal</ng-option>
                        <ng-option value="BANK_TRANSFER">{{ 'order.bankTransfer' | translate }}</ng-option>
                        <ng-option *ngIf="isBirdOrder" value="VOUCHER">{{
                            'order.payment.VOUCHER' | translate
                        }}</ng-option>
                        <ng-option *ngIf="isBirdOrder && language === 'sk'" value="POST_VOUCHER">{{
                            'order.payment.POST_VOUCHER' | translate
                        }}</ng-option>
                    </ng-select>
                    <ui-show-errors controlName="paymentMethod"></ui-show-errors>
                </div>
                <div class="col-12 mt-2 alert d-block alert-info" *ngIf="headerForm.value.paymentMethod === 'PAYPAL'">
                    <i class="fas fa-info-circle mr-1"></i> {{ 'order.paypalWarn' | translate }}
                </div>
                <div class="col-12 mt-2 pl-0" *ngIf="headerForm.value.paymentMethod === 'VOUCHER'">
                    <span class="font-lg block">{{ 'voucher.enterVouchersPrompt' | translate }}</span>
                    <div>
                        <div *ngFor="let item of vouchers" class="flex align-items-center justify-content-between py-2">
                            <oc-voucher-badge [voucher]="item"></oc-voucher-badge>
                            <button
                                pButton
                                type="button"
                                class="p-button-sm p-button-danger p-button-text"
                                (click)="deleteVoucher(item.id)"
                            >
                                {{ 'common.remove' | translate }}
                            </button>
                        </div>
                        <div class="flex align-items-center justify-content-between">
                            <p-dropdown
                                #voucherSearch
                                [options]="vouchers$ | async"
                                [editable]="true"
                                [placeholder]="'voucher.enterIdPlaceholder' | translate"
                                dataKey="id"
                                [showClear]="true"
                                field="id"
                                optionLabel="id"
                            ></p-dropdown>

                            <button
                                pButton
                                type="button"
                                [loading]="voucherLoading"
                                class="p-button-sm white-space-nowrap"
                                (click)="addVoucher(voucherSearch.value, voucherSearch)"
                            >
                                {{ 'voucher.applyVoucherCustomer' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-form-label col-sm-4">{{ 'order.resultsBy' | translate }}</label>
            <div class="col-sm-8 col-form-label">
                <div class="field-checkbox">
                    <p-checkbox
                        [binary]="true"
                        name="resultsByEmail"
                        inputId="resultsByEmail"
                        [formControl]="headerForm.controls['resultsByEmail']"
                    ></p-checkbox>
                    <label class="form-check-label" for="resultsByEmail">{{
                        'order.resultsByEmail' | translate
                    }}</label>
                </div>
                <div class="field-checkbox" *ngIf="!mailDisabled">
                    <p-checkbox
                        [binary]="true"
                        name="resultsByMail"
                        inputId="resultsByMail"
                        [formControl]="headerForm.controls['resultsByMail']"
                    ></p-checkbox>
                    <label class="form-check-label" for="resultsByMail">{{ 'order.resultsByMail' | translate }}</label>
                </div>
                <ui-show-errors [control]="headerForm"></ui-show-errors>

                <div class="col-12 alert mt-2 alert-info" *ngIf="headerForm.value.resultsByMail && !isBirdOrder">
                    <i class="fas fa-info-circle mr-1"></i> {{ 'order.postalFee' | translate }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="resultNotificationMode" class="col-sm-4 col-form-label"
                >{{ 'order.resultNotificationMode' | translate }}?</label
            >
            <div class="col-sm-8 col-xl-6 d-flex align-items-center">
                <ng-select
                    style="flex: 1"
                    formControlName="resultNotificationMode"
                    [searchable]="false"
                    [clearable]="false"
                    class=""
                >
                    <ng-option value="DISABLED">{{ 'order.resultNotificationMode.DISABLED' | translate }}</ng-option>
                    <ng-option value="IMMEDIATE">{{ 'order.resultNotificationMode.IMMEDIATE' | translate }}</ng-option>
                    <ng-option value="SCHEDULED">{{ 'order.resultNotificationMode.SCHEDULED' | translate }}</ng-option>
                </ng-select>
                <span
                    class="hidden md:block"
                    tooltipEvent="hover"
                    pTooltip="{{
                        'order.resultNotificationMode.' +
                            form.value.orderHeader.resultNotificationMode +
                            '.customerTooltip' | translate
                    }}"
                    [escape]="false"
                >
                    <i class="fas fa-question-circle mx-2 text-xl"></i>
                </span>
                <span
                    class="block md:hidden"
                    tabindex="0"
                    tooltipEvent="focus"
                    pTooltip="{{
                        'order.resultNotificationMode.' +
                            form.value.orderHeader.resultNotificationMode +
                            '.customerTooltip' | translate
                    }}"
                    [escape]="false"
                    #hint1
                    (click)="$event.stopPropagation(); hint1.focus()"
                >
                    <i class="fas fa-question-circle mx-2 text-xl"></i>
                </span>
            </div>
        </div>

        <div class="form-group row no-border">
            <label class="col-form-label col-sm-4">{{ 'order.note' | translate }}</label>
            <div class="col-sm-8 col-xl-6">
                <input
                    type="text"
                    formControlName="note"
                    placeholder="{{ 'order.note' | translate }}"
                    class="form-control"
                />
            </div>
        </div>
        <ui-form-errors [form]="headerForm"></ui-form-errors>
        <div class="text-md text-slim text-muted mt-3" [innerHTML]="'order.acknowledge' | translate"></div>
    </form>
</div>

<div class="button-bar d-flex justify-content-between mt-2">
    <button type="button" class="btn btn-info" (click)="back()">
        {{ 'order.backToAnalyses' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="next()" [disabled]="!headerForm.valid">
        {{ 'order.nextToSummary' | translate }}
    </button>
</div>
