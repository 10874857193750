import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'

import * as Sentry from '@sentry/angular-ivy'
import { environment } from './environments/environment'

if (environment.production) {
    enableProdMode()
    Sentry.init({
        dsn: environment.sentryDSN,
        release: environment.version,
        integrations: [
            new Sentry.Feedback({
                // Additional SDK configuration goes in here, for example:
                colorScheme: 'light',
                autoInject: false,
                showBranding: false,
            }),
        ],
    })
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
