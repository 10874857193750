<ng-template #breadcrumbAction>
    <a class="btn btn-sm btn-primary mr-2" routerLink="/new-order" role="button">{{ 'order.newOrder' | translate }}</a>
    <a class="btn btn-sm btn-info" routerLink="/sampling-orders/new" role="button">{{
        'samplingOrder.new' | translate
    }}</a>
</ng-template>

<ui-page pageTitle="'orders.myOrders'" [card]="false" [breadcrumbAction]="breadcrumbAction">
    <div
        *ngIf="drafts$ | async as draft"
        class="flex align-items-center flex-column gap-1 md:gap-3 lg:flex-row p-1 md:p-4 bg-blue-100 border-round border-1 border-blue-300 mb-2"
    >
        <i class="pi pi-info-circle text-blue-900 text-2xl"></i>
        <div class="flex flex-column text-center md:text-left">
            <span class="text-blue-900 font-medium mb-1 lg:mb-0 line-height-3">{{
                'order.draftExists' | translate
            }}</span>
            <span class="text-blue-900"
                >{{ 'order.draftDate' | translate }} {{ draft.updatedOn | date : 'shortDate' }}</span
            >
        </div>
        <a [routerLink]="['/new-order']" class="btn btn-primary btn-sm" role="button">{{
            'order.continueDraft' | translate
        }}</a>
    </div>

    <div class="card">
        <div class="card-body">
            <form [formGroup]="form" novalidate>
                <div class="row">
                    <div class="col-6 col-md-4">
                        {{ 'order.dateFrom' | translate }}
                        <p-calendar formControlName="from" default></p-calendar>
                    </div>
                    <div class="col-6 col-md-4">
                        {{ 'order.dateTo' | translate }}
                        <p-calendar formControlName="to"></p-calendar>
                    </div>
                    <div class="col-6 col-md-4">
                        {{ 'order.status' | translate }}
                        <ng-select [items]="statuses" bindLabel="label" bindValue="id" formControlName="status">
                        </ng-select>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <button class="btn btn-primary" (click)="search()">{{ 'common.search' | translate }}</button>
        </div>
    </div>
    <div class="card-body p-0">
        <p-table
            #dt
            [value]="orderList$ | async"
            dataKey="id"
            styleClass="p-datatable-orders"
            [rowHover]="true"
            [rows]="20"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 20, 50]"
            [loading]="loading"
            [paginator]="true"
            [responsive]="false"
            [autoLayout]="true"
            currentPageReportTemplate="{{ 'common.pagination' | translate }}"
            [filterDelay]="0"
            [globalFilterFields]="['name', 'country.name', 'representative.name', 'status']"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 130px">{{ 'order.id' | translate }}</th>
                    <th style="width: 160px">{{ 'order.date' | translate }}</th>
                    <th style="width: 140px">{{ 'order.status' | translate }}</th>
                    <th style="width: 10rem">{{ 'order.paymentMethod' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr [routerLink]="['/my-orders', order.id]" style="cursor: pointer">
                    <td class="nowrap">
                        {{ order.id }}
                    </td>

                    <td class="nowrap">
                        {{ order.orderDate | date }}
                    </td>
                    <td class="nowrap">
                        <span [class]="'status-badge ' + order.status">{{
                            'order.status.' + order.status | translate
                        }}</span>
                    </td>
                    <td class="nowrap">
                        <span *ngIf="order.paymentMethod">{{
                            'order.payment.' + order.paymentMethod | translate
                        }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</ui-page>
