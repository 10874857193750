<div class="card card-outline-primary border-0">
    <div class="card-header bg-primary text-white">
        {{ 'order.items' | translate }}
    </div>
    <div class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-bordered m-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'order.sample' | translate }}</th>
                        <th>{{ 'sample.type' | translate }}</th>
                        <th>{{ 'order.analysis' | translate }}</th>
                        <th>{{ 'sample.samplingDate' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.sampleLabel }}</td>
                        <td>{{ item.extractionMethodLabel }}</td>
                        <td>{{ item.analysisLabel }}</td>
                        <td>{{ item.samplingDate | date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="row text-left mt-4">
    <div class="col-sm-6 col-md-4">{{ 'order.note' | translate }}</div>
    <div class="col-sm-6 col-md-8">
        <strong>{{ formValue.orderHeader.note }}</strong>
    </div>
</div>
<div class="row text-left">
    <div class="col-sm-6 col-md-4">{{ 'order.paymentMethod' | translate }}</div>
    <div class="col-sm-6 col-md-8">
        <strong *ngIf="formValue.orderHeader.paymentMethod">{{
            'order.payment.' + formValue.orderHeader.paymentMethod | translate
        }}</strong>
        <div *ngIf="formValue.orderHeader.vouchersUsed" class="py-2">
            <span class="border-1 mr-2 p-1">{{ formValue.orderHeader.vouchersUsed }}</span>
        </div>
    </div>
</div>
<div class="row text-left">
    <div class="col-sm-6 col-md-4">{{ 'order.samplingKitRequested' | translate }}</div>
    <div class="col-sm-6 col-md-8">
        <strong>
            <span *ngIf="formValue.orderHeader.samplingKitType !== -1">
                {{ 'common.yes' | translate }} -
                {{ extractionMethodMap[formValue.orderHeader.samplingKitType]?.label }}
                <span> / {{ formValue.orderHeader.samplingKitCount }}</span>
            </span>
            <span *ngIf="formValue.orderHeader.samplingKitType === -1">{{ 'common.no' | translate }}</span>
        </strong>
    </div>
</div>
<div class="text-md text-slim mt-3" [innerHTML]="'order.finish.info' | translate"></div>

<div class="button-bar d-flex justify-content-between mt-2">
    <button type="button" class="btn btn-info" (click)="onPrev()">
        {{ 'order.goBack' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()">
        {{ 'order.finish' | translate }}
    </button>
</div>
