<ui-page [card]="false">
    <h4>{{ 'order.summary' | translate }}</h4>
    <ng-template #loadingTemplate>
        <ui-loading-indicator [show]="loading"></ui-loading-indicator>
    </ng-template>
    <div class="text-center panel-padding" class="print-only" id="printableOrder" *ngIf="currentOrder">
        <order-printable [currentOrder]="currentOrder"></order-printable>
    </div>
    <div class="text-center panel-padding" *ngIf="currentOrder; else loadingTemplate">
        <ui-loading-indicator [show]="loading"></ui-loading-indicator>
        <div class="alert alert-success">
            <strong>{{ 'order.newSaveOk' | translate }}</strong>
        </div>
        <div class="c-callout c-callout-warning alert-warning c-elevation-2 py-2">
            <p>
                <strong>{{ 'order.important' | translate }}</strong> {{ 'order.printPrompt' | translate }}
                <button class="btn btn-primary" printSectionId="printableOrder" print [useExistingCss]="true">
                    {{ 'order.printNow' | translate }}
                </button>
            </p>
            <p>
                {{ 'order.printPrompt.later' | translate }}
                <a [routerLink]="['/my-orders', currentOrder.id]">
                    <i class="fas fa-external-link-alt mr-1"></i>{{ 'order.printPrompt.here' | translate }}</a
                >
            </p>
            <p>{{ 'order.printFallback' | translate }}: {{ currentOrder.id }}</p>
        </div>

        <h2>
            {{ 'order' | translate }}:
            <a [routerLink]="['/my-orders', currentOrder.id]"
                ><strong>{{ currentOrder.id }}</strong></a
            >
        </h2>
        <p>
            <strong> {{ 'order.date' | translate }}: {{ currentOrder.createdOn | date }} </strong>
        </p>
        <div class="row">
            <div class="print-only col-sm-6">
                <div class="float-left">
                    <h3>{{ 'order.customer' | translate }}</h3>
                    <p>{{ currentOrder.customer.name }} {{ currentOrder.customer.surname }}</p>
                    <p>{{ currentOrder.customer.address.street }}</p>
                    <p>{{ currentOrder.customer.address.city }}</p>
                    <p>{{ currentOrder.customer.address.country }}</p>
                    <p></p>
                </div>
            </div>

            <div class="print-only col-sm-6">
                <div class="float-right">
                    <h3>{{ 'order.samplingByVet' | translate }}</h3>
                    <p style="font-size: x-small">{{ 'order.samplingByVetNote' | translate }}</p>
                    <div class="row">
                        <div class="col-sm-6">{{ 'order.vetName' | translate }}</div>
                        <div class="col-sm-6">
                            <p style="border-bottom: 1px dotted black">&nbsp;</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">{{ 'sample.samplingVetNo' | translate }}</div>
                        <div class="col-sm-6">
                            <p style="border-bottom: 1px dotted black">&nbsp;</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">{{ 'order.vetAddress' | translate }}</div>
                        <div class="col-sm-6">
                            <p style="border-bottom: 1px dotted black">&nbsp;</p>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 30px">
                        <div class="col-sm-6">{{ 'order.vetStamp' | translate }}</div>
                        <div class="col-sm-6"><p style="border-bottom: 1px dotted black">&nbsp;</p></div>
                    </div>
                    <p></p>
                </div>
            </div>
        </div>

        <div class="card card-outline-primary print-as-table">
            <div class="card-header bg-primary text-white">
                {{ 'order.items' | translate }}
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-bordered" data-test-id="sumarytable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ 'order.sample' | translate }}</th>
                                <th>{{ 'sample.type' | translate }}</th>
                                <th>{{ 'order.analysis' | translate }}</th>
                                <th>{{ 'sample.samplingDate' | translate }}</th>
                                <th>{{ 'sample.labCode' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of currentOrder.items | sort : 'asc' : 'id'; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item.sampleLabel }}</td>
                                <td>{{ item.sample.extractionMethod.name }}</td>
                                <td>{{ item.analysisLabel }}</td>
                                <td>{{ item.sample.samplingDate | date }}</td>
                                <td>{{ item.sample.labCode }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row text-left mt-4">
            <div class="col-sm-6">{{ 'order.note' | translate }}</div>
            <div class="col-sm-6">
                <strong>{{ currentOrder.note }}</strong>
            </div>
        </div>
        <div class="row text-left">
            <div class="col-sm-6">{{ 'order.paymentMethod' | translate }}</div>
            <div class="col-sm-6">
                <strong *ngIf="currentOrder.paymentMethod">{{
                    'order.payment.' + currentOrder.paymentMethod | translate
                }}</strong>
                <div *ngIf="currentOrder.vouchers && currentOrder.vouchers.length > 0" class="py-2">
                    <span class="border-1 mr-2 p-1" *ngFor="let v of currentOrder.vouchers">{{ v.id }}</span>
                </div>
            </div>
        </div>
        <div class="row text-left">
            <div class="col-sm-6">{{ 'order.samplingKitRequested' | translate }}</div>
            <div class="col-sm-6">
                <strong>
                    <span *ngIf="currentOrder.samplingKitRequested">
                        {{ 'common.yes' | translate }} -
                        {{ extractionMethodMap[currentOrder.samplingKitType].label }}
                    </span>
                    <span *ngIf="!currentOrder.samplingKitRequested">{{ 'common.no' | translate }}</span>
                </strong>
            </div>
        </div>
    </div>
</ui-page>
