<div class="block-screen" *ngIf="submitting">
    <b>...</b>
</div>

<p-confirmDialog
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50vw' }"
    key="confirmCancel"
    position="top"
    #cdCancel
    baseZIndex="2000"
    header="{{ 'order.cancelConfirmation' | translate }}"
    icon="pi pi-exclamation-triangle"
    message="{{ 'order.cancelConfirmation.message' | translate }}"
>
    <p-footer>
        <button
            type="button"
            class="btn btn-primary"
            pButton
            icon="pi pi-check"
            label="{{ 'order.cancelAffirm' | translate }}"
            (click)="cdCancel.accept()"
        ></button>
        <button
            type="button"
            class="btn btn-outline-dark"
            pButton
            icon="pi pi-times"
            label="{{ 'order.cancelCancel' | translate }}"
            (click)="cdCancel.reject()"
        ></button>
    </p-footer>
</p-confirmDialog>

<p-confirmDialog
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50vw' }"
    key="confirmSimpleSample"
    position="top"
    baseZIndex="2000"
    #cdSingleSample
    header="{{ 'order.confirmSamplesTitle' | translate }}"
    message="{{ 'order.confirmSamplesComplete' | translate }}"
>
    <p-footer>
        <button
            type="button"
            class="btn btn-primary"
            pButton
            icon="pi pi-check"
            label="{{ 'order.confirmSamplesBtn' | translate }}"
            (click)="cdSingleSample.accept()"
        ></button>
        <button
            type="button"
            class="btn btn-outline-dark"
            pButton
            icon="pi pi-times"
            label="{{ 'common.cancel' | translate }}"
            (click)="cdSingleSample.reject()"
        ></button>
    </p-footer>
</p-confirmDialog>

<ng-template #breadCrumb>
    <button type="button" class="btn btn-sm btn-warning mr-2" (click)="confirmCancel()">
        {{ 'common.cancel' | translate }}
    </button>
    <button pButton class="p-button-sm btn-sm" [loading]="orderSaveInProgress">
        <span *ngIf="orderSaveInProgress">{{ 'order.draftSaving' | translate }}</span>
        <span *ngIf="!orderSaveInProgress" class="hidden md:block">{{ 'order.saveDraft' | translate }}</span>
        <span *ngIf="!orderSaveInProgress" class="md:hidden">{{ 'order.saveDraftShort' | translate }}</span>
    </button></ng-template
>
<ui-page [card]="false" [breadcrumbAction]="breadCrumb">
    <span id="moveTop"></span>
    <ui-loading-indicator [show]="loading"></ui-loading-indicator>
    <oc-order-steps-indicator [currentStage]="step" [progress]="progress"></oc-order-steps-indicator>

    <p-blockUI [target]="formpanel" [blocked]="loading">
        <i class="pi pi-hourglass" style="font-size: 3rem"></i> <span>{{ 'common.loading' | translate }}</span>
    </p-blockUI>
    <blockable-div #formpanel class="order-wizard mt-2" [formGroup]="mainForm">
        <oc-sample-list-form
            *ngIf="step === 'SAMPLES'"
            formArrayName="samples"
            [customerSamples]="customerSamples$"
        ></oc-sample-list-form>

        <div class="button-bar d-flex justify-content-between mb-2" *ngIf="step === 'SAMPLES'">
            <button type="button" class="btn btn-primary" (click)="onAddSample()">
                {{ 'order.addSample' | translate }}
            </button>
            <button type="button" class="btn btn-warning" (click)="confirmCancel()">
                {{ 'common.cancel' | translate }}
            </button>
            <button type="button" [disabled]="!f.samples.valid" class="btn btn-primary" (click)="confirmSingleSample()">
                {{ 'order.nextStep' | translate }}
            </button>
        </div>

        <resultsgate-ui-order-completion
            *ngIf="step === 'COMPLETION'"
            formGroupName="orderHeader"
            [form]="mainForm"
            (finish)="toSummary()"
            (prev)="toSamples()"
        >
        </resultsgate-ui-order-completion>
        <resultsgate-ui-order-review
            *ngIf="step === 'SUMMARY'"
            [form]="mainForm"
            [customerSamples$]="customerSamples$"
            (prev)="toCompletion()"
            (submitOrder)="submit()"
        ></resultsgate-ui-order-review>
    </blockable-div>
    <pre *ngIf="development">Debug {{ mainForm.getRawValue() | json }}</pre>
</ui-page>
