import { Component, OnInit } from '@angular/core'

@Component({
    templateUrl: './activation.component.html',
    styles: [],
})
export class ActivationComponent implements OnInit {
    ngOnInit(): void {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')
        window.window.location.href = `${window.location.origin}/api/auth/activation/${token}`
    }
}
