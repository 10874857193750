<div class="row">
    <div class="col-6">
        <button type="button" class="btn btn-primary btn-block" [routerLink]="['/new-order']">
            <i class="fas fa-plus"></i>
            {{ 'order.newOrder' | translate }}
        </button>
    </div>
    <div class="col-6">
        <button type="button" class="btn btn-info btn-block" [routerLink]="['/sampling-orders/new']">
            <i class="fas fa-vials"></i>
            {{ 'samplingOrder.new' | translate }}
        </button>
    </div>
</div>

<div *ngIf="overview.total === 0" class="note note-info p-3">
    {{ 'customer.noOrders' | translate }}<br />
    <br />{{ 'customer.noOrders.prompt' | translate }}<br />
    <br />
</div>
<ng-container *ngIf="drafts$ | async as drafts">
    <div class="card mt-3 card-accent-success p-4" *ngIf="drafts && drafts.length > 0">
        <div class="flex flex-column gap-2 md:flex-row">
            <div class="flex flex-column text-center md:text-left">
                <span class="text-blue-900 font-medium mb-1 lg:mb-0 line-height-3">{{
                    'order.draftExists' | translate
                }}</span>
                <span class="text-blue-900"
                    >{{ 'order.draftDate' | translate }} {{ drafts[0].updatedOn | date : 'shortDate' }}</span
                >
            </div>

            <a [routerLink]="['/new-order']" class="align-self-center btn btn-primary btn-sm ml-2" role="button">{{
                'order.continueDraft' | translate
            }}</a>
            <a (click)="deleteDraft()" class="align-self-center btn btn-outline-danger btn-sm ml-2" role="button">{{
                'order.draftCancel' | translate
            }}</a>
        </div>
    </div>
</ng-container>

<div class="card mt-3 card-accent-success">
    <div class="card-header">
        <i class="fas fa-file-invoice mr-2"></i>
        {{ 'customer.overview' | translate }} {{ 'customer.overview.subtitle' | translate }}
    </div>
    <div class="card-body">
        <div class="list-group" *ngIf="data$ | async; let data">
            <a
                [routerLink]="['/my-orders']"
                routerLinkActive="router-link-active"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                {{ 'customer.pendingOrders' | translate }}<span class="badge badge-info">{{ data.inProgress }}</span>
            </a>
            <a [routerLink]="['/my-orders']" class="list-group-item d-flex justify-content-between align-items-center">
                {{ 'customer.completeOrders' | translate }}<span class="badge badge-warning">{{ data.completed }}</span>
            </a>
            <a [routerLink]="['/my-orders']" class="list-group-item d-flex justify-content-between align-items-center">
                {{ 'customer.total' | translate }}<span class="badge badge-danger">{{ data.total }}</span>
            </a>
        </div>
    </div>
</div>

<div class="card card-accent-success">
    <div class="card-header"><i class="fas fa-comment mr-2"></i>{{ 'notifications' | translate }}</div>
    <div class="card-body" *ngIf="data$ | async; let data">
        <div *ngFor="let item of data.pendingSurveys" class="alert alert-info">
            <a [routerLink]="['/surveys', item.id, 'submission']">
                {{ 'survey.info' | translate }} <span class="ml-1">{{ item.orderId }}</span>
            </a>
        </div>
        <div *ngIf="data.pendingSurveys.length === 0" class="alert alert-info">
            {{ 'notifications.empty' | translate }}
        </div>
    </div>
</div>
