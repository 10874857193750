import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard, LoginComponent, P403Component, P404Component } from '@resultsgate-ui/shared'
import { ActivationComponent } from './activation/activation.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { MyOrdersComponent } from './pages/my-orders/my-orders.component'
import { MySamplesComponent } from './pages/my-samples/my-samples.component'
import { NewOrderComponent } from './pages/new-order/new-order.component'
import { OrderSummaryComponent } from './pages/new-order/order-summary/order-summary.component'
import { OrderDetailComponent } from './pages/order-detail/order-detail.component'

export const routes: Routes = [
    {
        path: '404',
        component: P404Component,
        data: {
            title: 'Page 404',
            shell: false,
        },
    },
    {
        path: '403',
        component: P403Component,
        data: {
            title: 'Page 403',
            shell: false,
        },
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login Page',
            shell: false,
        },
    },
    {
        path: 'auth/activation',
        component: ActivationComponent,
        data: {
            shell: false,
        },
    },
    {
        path: 'account',
        data: { breadrumb: 'menu.profile' },
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'surveys',
        loadChildren: () => import('./surveys/surveys.module').then((m) => m.SurveysModule),
    },
    {
        data: { roles: ['customer'], breadcrumb: 'menu.myOrders' },
        path: 'my-orders',

        children: [
            {
                path: '',
                pathMatch: 'full',
                component: MyOrdersComponent,
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                data: { roles: ['customer'], breadcrumb: 'order.breadcrumb' },
                path: ':id',
                component: OrderDetailComponent,
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
        ],
    },

    {
        data: { roles: ['customer'], breadcrumb: 'menu.mySamples' },
        path: 'my-samples',
        component: MySamplesComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        data: { roles: ['customer'], breadcrumb: 'order.newOrder' },
        path: 'new-order',
        component: NewOrderComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        data: { roles: ['customer'], breadcrumb: 'order.summary' },
        path: 'new-order-summary/:id',
        component: OrderSummaryComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        path: 'calendar',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: { roles: ['customer'], breadcrumb: 'menu.calendar' },
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
    },
    {
        path: '',
        pathMatch: 'full',
        data: { roles: ['customer'], breadcrumb: 'menu.home' },
        component: DashboardComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        path: 'sampling-orders',
        data: { roles: ['customer'], breadcrumb: 'menu.samplingOrders' },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () =>
            import('./sampling-kit-orders/sampling-kit-orders.module').then((m) => m.SamplingKitOrdersModule),
    },
    {
        path: 'verify',
        loadChildren: () => import('./verification/verification.module').then((m) => m.VerificationModule),
        data: {
            title: 'Verification',
            shell: false,
        },
    },
    {
        path: 'my-vouchers',
        loadComponent: () => import('./vouchers/voucher-list.component').then((m) => m.VoucherListComponent),
        data: { roles: ['customer'], breadcrumb: 'menu.vouchers' },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },

    { path: '**', component: P404Component, data: { shell: false } },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
