import { MenuItem } from '@resultsgate-ui/shared'
export const navItems: MenuItem[] = [
    {
        name: 'menu.home',
        route: '/',
        routeExactMatch: true,
        icon: 'fas fa-tachometer-alt',
        roles: ['customer'],
    },
    {
        name: 'menu.profile',
        route: '/account/profile',
        icon: 'fas fa-id-card',
    },
    {
        name: 'menu.myOrders',
        route: '/my-orders',
        icon: 'fas fa-file-invoice',
        roles: ['customer'],
    },

    {
        name: 'menu.samplingOrders',
        route: '/sampling-orders',
        icon: 'fas fa-vial',
        roles: ['customer'],
    },

    {
        name: 'menu.mySamples',
        route: '/my-samples',
        icon: 'fas fa-dna',
        roles: ['customer'],
    },

    {
        name: 'menu.myVouchers',
        route: '/my-vouchers',
        icon: 'fas fa-ticket-alt',
        roles: ['customer'],
    },

    {
        name: 'menu.calendar',
        route: '/calendar',
        icon: 'fas fa-calendar-alt',
    },
    {
        title: true,
        name: 'menu.contacts',
    },
    {
        name: 'menu.slovgen',
        url: 'https://slovgen.sk',
        icon: 'fas fa-home',
        variant: 'info',
        attributes: { target: '_blank', rel: 'noopener' },
    },
    {
        name: 'menu.sendUsAMessage',
        url: 'mailto:info@slovgen.sk',
        icon: 'fas fa-at',
        variant: 'danger',
        attributes: { target: '_blank', rel: 'noopener' },
    },
]
