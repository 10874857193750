<ui-page [card]="false">
    <ng-template #loadingTpl>
        <ui-loading-indicator [show]="loading"></ui-loading-indicator>
    </ng-template>

    <ng-container *ngIf="order; else loadingTpl">
        <!-- printable portion -->

        <div class="text-center panel-padding" class="print-only" id="printableOrder" *ngIf="order">
            <order-printable [currentOrder]="order"></order-printable>
        </div>
        <section id="fullPrintableOrder">
            <!-- print CTA-->

            <div class="print-invisible" *ngIf="order.status === 'NEW'">
                <div class="c-callout c-callout-info alert-info c-elevation-2 py-2">
                    <p>
                        {{ 'order.printPrompt.detail' | translate }}
                        <button class="btn btn-primary" printSectionId="printableOrder" print [useExistingCss]="true">
                            {{ 'order.printNow' | translate }}
                        </button>
                    </p>

                    <p>{{ 'order.printFallback' | translate }}: {{ order.id }}</p>
                </div>
            </div>

            <div class="col-12 grid-gutter-padding-h">
                <h2 class="flex">
                    {{ 'order.id' | translate }}
                    <span class="font-bold ml-2">{{ id }}</span>

                    <button
                        class="btn btn-info btn-sm ml-auto print-invisible"
                        printSectionId="fullPrintableOrder"
                        print
                        [useExistingCss]="true"
                    >
                        {{ 'order.printNow' | translate }}
                    </button>
                </h2>

                <div class="row">
                    <span class="col-md-3 col-sm-6">{{ 'order.status' | translate }}</span>

                    <div class="col-md-9 col-sm-6">
                        <oc-order-status [status]="order.status"></oc-order-status>
                    </div>
                </div>

                <div class="row">
                    <span class="col-md-3 col-sm-6">{{ 'common.createdOn' | translate }}</span>

                    <strong class="col-md-9 col-sm-6">{{ order.createdOn | date : 'short' }}</strong>
                </div>

                <div class="row">
                    <span class="col-md-3 col-sm-6">{{ 'sample.receiptDate' | translate }}</span>

                    <strong class="col-md-9 col-sm-6">{{ order.sampleReceiptDate | date : 'short' }}</strong>
                </div>

                <div class="row">
                    <span class="col-md-3 col-sm-6">{{ 'common.lastModification' | translate }}</span>

                    <!-- <strong>{{ order.updatedOn | date: 'short' }}</strong> -->
                </div>

                <div class="row">
                    <span class="col-md-3 col-sm-6">{{ 'order.paymentMethod' | translate }}</span>

                    <strong class="col-md-9 col-sm-6" *ngIf="order.paymentMethod">
                        {{ 'order.payment.' + order.paymentMethod | translate }}
                    </strong>
                </div>

                <div class="row" *ngIf="order.invoiceNo">
                    <span class="col-md-3 col-sm-6">{{ 'order.invoice' | translate }}</span>

                    <div class="col-md-9 col-sm-6">
                        <strong>{{ order.invoiceNo }}</strong>

                        <a
                            href=""
                            *ngIf="order.invoiceFile"
                            class="btn btn-sm btn-info ml-2 print-invisible"
                            (click)="downloadCertFile(order.invoiceFile)"
                            target="_blank"
                        >
                            {{ 'common.download' | translate }}
                        </a>
                    </div>
                </div>
            </div>

            <div
                class="w-full px-2 flex justify-content-end print-invisible"
                *ngIf="order.status === 'PAID' || order.status === 'COMPLETED'"
            >
                <button pButton class="btn-primary" [loading]="pdfBundleLoading" (click)="downloadPdfBundle()">
                    {{ 'order.downloadZipBundle' | translate }}
                </button>
            </div>

            <div class="w-full card card-outline-primary mt-3 print-as-table">
                <div class="card-header bg-info text-white font-bold border-none">{{ 'order.items' | translate }}</div>

                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-borderless table-striped">
                            <thead>
                                <tr class="bg-info text-white">
                                    <th>#</th>

                                    <th>{{ 'order.sample' | translate }}</th>

                                    <th>{{ 'order.analysis' | translate }}</th>

                                    <th>{{ 'order.analysisDate' | translate }}</th>

                                    <th>{{ 'orderItem.protocolNo' | translate }}</th>

                                    <th>{{ 'orderItem.result' | translate }}</th>

                                    <th class="print-invisible">{{ 'orderItem.showProtocol' | translate }}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of order.items | sort : 'asc' : 'id'; let index = index">
                                    <td>{{ index + 1 }}</td>

                                    <td>{{ item.sampleLabel }}</td>

                                    <td>{{ item.analysisLabel }}</td>

                                    <td>
                                        <span *ngIf="item.analysisDate">{{ item.analysisDate | date }}</span>

                                        <span *ngIf="!item.analysisDate">{{
                                            'orderItem.notYetAnalyzed' | translate
                                        }}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="item.protocolNo">{{ item.protocolNo }}</span>
                                    </td>

                                    <td>
                                        <span *ngIf="item.canDownloadProtocol">
                                            {{ item.resultText }}
                                        </span>

                                        <span
                                            *ngIf="
                                                item.resultText &&
                                                order.status !== 'COMPLETED' &&
                                                order.status !== 'PAID'
                                            "
                                        >
                                            {{ 'orderItem.resultsReadyWaitingForPayment' | translate }}
                                        </span>
                                    </td>

                                    <td class="print-invisible no-padding valign-middle text-center">
                                        <span *ngIf="!item.canDownloadProtocol">
                                            {{ 'orderItem.protocolNotPublished' | translate }}
                                        </span>

                                        <!-- <span *ngIf="!item.analysisDate && !item.analysisDisabled">
                                        {{ 'orderItem.waiting' | translate }}
                                    </span> -->
                                        <div class="flex flex-column xl:flex-row gap-1">
                                            <button
                                                class="btn btn-primary btn-sm"
                                                *ngIf="item.canDownloadProtocol"
                                                (click)="downloadFile(item.id, item.protocolNo)"
                                            >
                                                {{ 'orderItem.showProtocol' | translate }}
                                            </button>
                                            <button
                                                class="btn btn-primary btn-sm"
                                                *ngIf="item.canDownloadProtocol && item.certificateFileRef"
                                                (click)="downloadCertFile(item.certificateFileRef.id)"
                                            >
                                                {{ 'orderItem.showCertificate' | translate }}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row text-left">
                <div class="col-sm-3">{{ 'order.note' | translate }}</div>

                <div class="col-sm-9">
                    <strong>{{ order.note }}</strong>
                </div>
            </div>

            <div class="alert alert-info mt-3" role="alert" *ngIf="order.survey?.id && !order.survey?.submissionDate">
                <a class="alert-link" [routerLink]="['/surveys', order.survey.id, 'submission']">
                    {{ 'survey.waiting.prompt' | translate }}
                </a>
            </div>
        </section>
    </ng-container>
</ui-page>
