import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@ngneat/reactive-forms'

import { OrderFormModel, SampleFormModel } from '@resultsgate-ui/order-components'
import { CacheService, SelectItem } from '@resultsgate-ui/shared'
import { Observable } from 'rxjs'

@Component({
    selector: 'resultsgate-ui-order-review',
    templateUrl: './order-review.component.html',
    styles: [],
})
export class OrderReviewComponent implements OnInit {
    @Output()
    prev: EventEmitter<any> = new EventEmitter()
    @Output()
    submitOrder: EventEmitter<any> = new EventEmitter()
    @Input()
    form: FormGroup<OrderFormModel>

    @Input()
    customerSamples$: Observable<SelectItem<number>[]>

    items: { sampleLabel: string; analysisLabel: string; samplingDate?: Date; extractionMethodLabel: string }[] = []
    formValue: any = {}
    allCategoriesMap: Map<number, string> = new Map()
    analysisMap: Map<number, SelectItem<number>>
    existingSamples: SelectItem<number>[]
    extractionMethodMap: { [key: string]: SelectItem<number> }

    constructor(private cache: CacheService) {
        this.allCategoriesMap = this.cache.allCategoriesMap
        this.analysisMap = this.cache.analysisMap
        this.extractionMethodMap = this.cache.extractionMethodMap
    }

    ngOnInit(): void {
        this.formValue = this.form.getRawValue()
        this.customerSamples$.subscribe((data) => {
            this.existingSamples = data
            this.formValue.samples.forEach((s: SampleFormModel) => {
                s.items.forEach((i) => {
                    this.items.push({
                        sampleLabel: this.getSampleLabel(s),
                        analysisLabel: this.analysisMap.get(i.analysisId).label,
                        samplingDate: new Date(s.samplingDate),
                        extractionMethodLabel: s.extractionMethodId
                            ? this.extractionMethodMap[s.extractionMethodId].label
                            : '',
                    })
                })
            })
        })
    }

    getSampleLabel(s: SampleFormModel) {
        if (s.existingSampleId) {
            const found = this.existingSamples.find((i) => i.id === s.existingSampleId)
            return `${found.label}`
        } else {
            return `${
                s.animal.name ||
                s.animal.identifiers.certificateId ||
                s.animal.identifiers.rfid ||
                s.animal.identifiers.tattooId
            } | ${
                this.allCategoriesMap.get(s.animal.categoryId).startsWith('--')
                    ? s.animal.categoryInfo
                    : this.allCategoriesMap.get(s.animal.categoryId)
            }`
        }
    }

    onSubmit() {
        this.submitOrder.emit(true)
    }

    onPrev() {
        this.prev.emit(true)
    }
}
