<ui-page pageTitle="{{ 'samples.formFew' | translate }}" [card]="false">
    <div class="card">
        <div class="card-header">{{ 'menu.mySamples' | translate }}</div>
        <div class="card-body">
            <form [formGroup]="form" novalidate>
                <div class="col">
                    <div class="form-group row">
                        <label class="col-form-label col-md-2">{{ 'sample.labCode' | translate }}</label>
                        <div class="col-md-4">
                            <input type="text" class="form-control" formControlName="labCode" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-form-label col-md-2">{{ 'sample.nameShort' | translate }}</label>
                        <div class="col-md-4">
                            <input type="text" class="form-control" formControlName="name" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-form-label col-md-2">{{ 'sample.identifier' | translate }}</label>
                        <div class="col-md-4">
                            <input
                                type="text"
                                placeholder="{{ 'sample.identifier.placeholder' | translate }}"
                                class="form-control"
                                formControlName="identifier"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-form-label col-md-2">{{ 'order.dateFrom' | translate }}</label>
                        <div class="col-md-4">
                            <p-calendar formControlName="dateFrom"></p-calendar>
                        </div>

                        <label class="col-form-label col-md-2">{{ 'order.dateTo' | translate }}</label>
                        <div class="col-md-4">
                            <p-calendar formControlName="dateTo"></p-calendar>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 col-form-label">{{ 'sample.animal' | translate }}</label>
                        <div class="col-md-4">
                            <ng-select
                                [clearable]="true"
                                [bindValue]="'id'"
                                [bindLabel]="'label'"
                                [searchable]="false"
                                formControlName="rootCategoryId"
                                (change)="onRootCategorySelected($event)"
                                [items]="rootCategoriesSelectItems"
                            >
                            </ng-select>
                        </div>
                        <ng-container *ngIf="f.rootCategoryId.value">
                            <label class="col-md-2 col-form-label">{{ 'sample.breed' | translate }}</label>
                            <div class="col-md-4">
                                <ng-select
                                    formControlName="categoryId"
                                    [items]="cache.categoriesMap[f.rootCategoryId.value]"
                                    [bindValue]="'id'"
                                    [bindLabel]="'label'"
                                >
                                </ng-select>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <button class="btn btn-primary" (click)="search()">{{ 'common.search' | translate }}</button>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            {{ 'results' | translate }}
            <div class="card-header-actions">
                <ui-loading-indicator [show]="loadingIndicator"></ui-loading-indicator>
            </div>
        </div>
        <div class="card-body p-0">
            <p-table
                [value]="samplesList$ | async"
                dataKey="id"
                [rowExpandMode]="'single'"
                [loading]="loadingIndicator"
                [responsive]="false"
                [autoLayout]="true"
                (onRowExpand)="onDetailToggle($event)"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"></th>
                        <th pSortableColumn="labCode">
                            {{ 'sample.labCode' | translate }} <p-sortIcon field="labCode"></p-sortIcon>
                        </th>
                        <th pSortableColumn="samplingDate">
                            {{ 'sample.samplingDate' | translate }} <p-sortIcon field="samplingDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="receivedDate">
                            {{ 'sample.receiptDate' | translate }} <p-sortIcon field="receivedDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="animalName">
                            {{ 'sample.nameShort' | translate }} <p-sortIcon field="animalName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="animalName">
                            {{ 'sample.identifier' | translate }} <p-sortIcon field="identifiers"></p-sortIcon>
                        </th>
                        <th pSortableColumn="breed">
                            {{ 'sample.breed' | translate }} <p-sortIcon field="breed"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-sample let-expanded="expanded">
                    <tr>
                        <td>
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="sample"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                            ></button>
                        </td>
                        <td>{{ sample.labCode }}</td>
                        <td>{{ sample.samplingDate | localizedDate : 'shortDate' }}</td>
                        <td>{{ sample.receivedDate | localizedDate : 'shortDate' }}</td>
                        <td>{{ sample.animalName }}</td>
                        <td>{{ sample.identifiers }}</td>
                        <td>{{ sample.categoryInfo || sample.breed }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-sample>
                    <tr>
                        <td colspan="7" class="expanded p-2">
                            <div class="card">
                                <div class="card-header">
                                    <strong
                                        >{{ 'order.sample' | translate }} {{ sample.id }}
                                        {{ sample.animalName }}</strong
                                    >
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-3 form-control-label">
                                            {{ 'sample.labCode' | translate }}
                                        </div>
                                        <div class="col-sm-6 col-md-3">{{ sample.labCode }}</div>

                                        <div class="col-sm-6 col-md-3">{{ 'sample.breed' | translate }}</div>
                                        <div class="col-sm-6 col-md-3">{{ sample.breed }}</div>
                                        <div class="col-sm-6 col-md-3">{{ 'sample.furColor' | translate }}</div>
                                        <div class="col-sm-6 col-md-3">{{ sample.furColor }}</div>
                                        <div class="col-sm-6 col-md-3">
                                            {{ 'samplingOrder.samplingMethod' | translate }}
                                        </div>
                                        <div class="col-sm-6 col-md-3">{{ sample.extractionMethod.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #loadingOrders>
                                <span>{{ 'common.loading' | translate }}</span>
                            </ng-template>
                            <div class="card mb-0" *ngIf="orderList$ | async as orderList; else loadingOrders">
                                <div class="card-header">
                                    <strong>{{ 'sample.history' | translate }}</strong>
                                </div>
                                <div class="card-body p-0">
                                    <p-table
                                        [value]="orderList"
                                        dataKey="id"
                                        [rowHover]="true"
                                        [rows]="10"
                                        [showCurrentPageReport]="orderList.length > 10"
                                        [loading]="loading"
                                        [paginator]="orderList.length > 10"
                                        [responsive]="false"
                                        [autoLayout]="true"
                                        currentPageReportTemplate="{{ 'common.pagination' | translate }}"
                                    >
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width: 130px">{{ 'order.id' | translate }}</th>
                                                <th style="width: 160px">{{ 'order.analysisDate' | translate }}</th>
                                                <th style="width: 140px">{{ 'order.analysis' | translate }}</th>
                                                <th style="width: 10rem">{{ 'orderItem.result' | translate }}</th>
                                                <th style="width: 10rem">{{ 'orderItem.showProtocol' | translate }}</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-item>
                                            <tr>
                                                <td>
                                                    <a [routerLink]="['/my-orders', item.orderId]">{{
                                                        item.orderId
                                                    }}</a>
                                                </td>

                                                <td>
                                                    {{ item.analysisDate | date }}
                                                </td>
                                                <td>
                                                    {{ item.analysisLabel }}
                                                </td>
                                                <td>
                                                    <span *ngIf="item.canDownloadProtocol">
                                                        {{ item.resultText }}
                                                    </span>
                                                </td>
                                                <td class="no-padding valign-middle text-center">
                                                    <button
                                                        *ngIf="item.canDownloadProtocol"
                                                        class="btn btn-primary btn-sm"
                                                        (click)="downloadFile(item.orderId, item.id, item.protocolNo)"
                                                    >
                                                        {{ 'orderItem.showProtocol' | translate }}
                                                    </button>

                                                    <span *ngIf="!item.canDownloadProtocol">{{
                                                        'orderItem.waiting' | translate
                                                    }}</span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</ui-page>
